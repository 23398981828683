let rootUrl;
let apiUrl;
let serverUrl;
let practiceUrl;
let marketingUrl;
let stripeKey;
let ENV;
let sentryDsn;
let gaKey;
let partnerUrl;
let ldClientId;

const hostname = window && window.location && window.location.hostname;

if (hostname === 'localhost') {
  ENV = 'DEVELOPMENT';
  rootUrl = 'http://localhost:6010';
  apiUrl = 'http://localhost:3000/api/v2/marchel';
  serverUrl = 'http://localhost:3000';
  practiceUrl = 'http://localhost:8080';
  marketingUrl = 'http://localhost:8000';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  gaKey = 'UA-175189431-1';
  sentryDsn = null;
  partnerUrl = 'http://localhost:6020';
  ldClientId = '61aa7abf805c8710cfad8b2e';
} else if (hostname === 'staging-business.inkblottherapy.com') {
  ENV = 'STAGING';
  rootUrl = 'https://staging-business.inkblottherapy.com';
  apiUrl = 'https://api.inkblottherapy.com/api/v2/marchel';
  serverUrl = 'https://api.inkblottherapy.com';
  practiceUrl = 'https://staging.inkblotpractice.com';
  marketingUrl = 'https://staging-marketing.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  gaKey = 'UA-175189431-1';
  sentryDsn = null;
  partnerUrl = 'https://staging-partners.inkblottherapy.com';
  ldClientId = '61ba3223f940930e6232e840';
} else if (hostname === 'business.inkblottherapy.com') {
  ENV = 'PRODUCTION';
  rootUrl = 'https://business.inkblottherapy.com';
  apiUrl = 'https://api.inkblotpractice.com/api/v2/marchel';
  serverUrl = 'https://api.inkblotpractice.com';
  practiceUrl = 'https://app.inkblotpractice.com';
  marketingUrl = 'https://inkblottherapy.com';
  stripeKey = 'pk_live_iHFo2C08gcquDQatzHxxOdo9';
  gaKey = 'UA-175189431-1';
  sentryDsn = 'https://574bb79be74b4929a446d5224eb0fb32@sentry.io/1856589';
  partnerUrl = 'https://partners.inkblottherapy.com';
  ldClientId = '61a4f5fda8e6530f447dbcf9';
} else if (hostname === 'uat-business.inkblottherapy.com') {
  ENV = 'UAT';
  rootUrl = 'https://uat-business.inkblottherapy.com';
  apiUrl = 'https://uatapi.inkblottherapy.com/api/v2/marchel';
  serverUrl = 'https://uatapi.inkblottherapy.com';
  practiceUrl = 'https://uat.inkblotpractice.com';
  marketingUrl = 'https://marketing.inkblottherapy.com';
  stripeKey = 'pk_test_5IuQ76s23QQbsEwJQUFPdqUW';
  gaKey = 'UA-175189431-1';
  sentryDsn = null;
  ldClientId = '61ba324c1c39840e58ab0ffd';
} else if (hostname === 'us-business.inkblottherapy.com') {
  ENV = 'PRODUCTION';
  rootUrl = 'https://us-business.inkblottherapy.com';
  apiUrl = 'https://medapi.inkblottherapy.com/api/v2/marchel';
  serverUrl = 'https://medapi.inkblottherapy.com';
  practiceUrl = 'https://portal.inkblotpractice.com';
  marketingUrl = 'https://inkblottherapy.com';
  stripeKey = 'pk_live_iHFo2C08gcquDQatzHxxOdo9';
  gaKey = 'UA-175189431-1';
  sentryDsn = 'https://574bb79be74b4929a446d5224eb0fb32@sentry.io/1856589';
  ldClientId = '61a4f5fda8e6530f447dbcf9';
}

export const ROOT_URL = `${rootUrl}`;
export const API_URL = `${apiUrl}`;
export const SERVER_URL = `${serverUrl}`;
export const PRACTICE_URL = `${practiceUrl}`;
export const MARKETING_URL = `${marketingUrl}`;
export const STRIPE_KEY = `${stripeKey}`;
export const ENVIRONMENT = `${ENV}`;
export const SENTRY_DSN = sentryDsn;
export const GA_KEY = gaKey;
export const PARTNER_URL = partnerUrl;
export const LD_CLIENT_ID = ldClientId;
