/*
 *
 * CompanyCreation constants
 *
 */

export const FETCH_COMPANIES = 'app/CompanyCreation/FETCH_COMPANIES';
export const FETCH_COMPANIES_ERROR =
  'app/CompanyCreation/FETCH_COMPANIES_ERROR';
export const FETCH_COMPANIES_SUCCESS =
  'app/CompanyCreation/FETCH_COMPANIES_SUCCESS';

export const CREATE_COMPANY = 'app/CompanyCreation/CREATE_COMPANY';
export const CREATE_COMPANY_ERROR = 'app/CompanyCreation/CREATE_COMPANY_ERROR';
export const RESET_COMPANY_ERROR = 'app/CompanyCreation/RESET_COMPANY_ERROR';
export const CREATE_COMPANY_SUCCESS =
  'app/CompanyCreation/CREATE_COMPANY_SUCCESS';

export const CREATE_LOGO = 'app/CompanyCreation/CREATE_LOGO';
export const CREATE_LOGO_ERROR = 'app/CompanyCreation/CREATE_LOGO_ERROR';
export const CREATE_LOGO_SUCCESS = 'app/CompanyCreation/CREATE_LOGO_SUCCESS';

export const FETCH_AMS = 'app/CompanyCreation/FETCH_AMS';
export const FETCH_AMS_ERROR = 'app/CompanyCreation/FETCH_AMS_ERROR';
export const FETCH_AMS_SUCCESS = 'app/CompanyCreation/FETCH_AMS_SUCCESS';

export const FETCH_CP_NAVIGATORS = 'app/CompanyCreation/FETCH_CP_NAVIGATOR';
export const FETCH_CP_NAVIGATORS_ERROR =
  'app/CompanyCreation/FETCH_CP_NAVIGATOR_ERROR';
export const FETCH_CP_NAVIGATORS_SUCCESS =
  'app/CompanyCreation/FETCH_CP_NAVIGATOR_SUCCESS';

export const UPLOAD_EMPLOYEE_CSV = 'app/CompanyCreation/UPLOAD_EMPLOYEE_CSV';
export const UPLOAD_EMPLOYEE_CSV_SUCCESS =
  'app/CompanyCreation/UPLOAD_EMPLOYEE_CSV_SUCCESS';
export const UPLOAD_EMPLOYEE_CSV_ERROR =
  'app/CompanyCreation/UPLOAD_EMPLOYEE_CSV_ERROR';

export const EDIT_COMPANY = 'app/CompanyEdit/EDIT_COMPANY';
export const EDIT_COMPANY_ERROR = 'app/CompanyEdit/EDIT_COMPANY_ERROR';
export const EDIT_COMPANY_SUCCESS = 'app/CompanyEdit/EDIT_COMPANY_SUCCESS';
