import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import {
  UikNavPanel,
  UikNavSection,
  UikNavSectionTitle,
  UikNavLink,
} from '@uik/lib';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { signOut } from 'utils/auth';

function SideBar(props) {
  const { currentUser } = props;
  const { pathname } = props.history.location;

  return (
    <UikNavPanel style={{ flexBasis: '20%', height: 'unset' }}>
      <UikNavSection>
        <UikNavSectionTitle>Main Menu</UikNavSectionTitle>
        <UikNavLink
          className={pathname.includes('/my_account') ? 'active' : ''}
          icon={<i className="fa fa-user" />}
          Component={Link}
          to="/my_account/company_information"
        >
          My Account
        </UikNavLink>

        {currentUser && currentUser.owner_yn && (
          <UikNavLink
            className={pathname === '/team_management' ? 'active' : ''}
            icon={<i className="fa fa-users" />}
            Component={Link}
            to="/team_management"
          >
            Team Management
          </UikNavLink>
        )}
        {currentUser && currentUser.inkblot_admin_yn && (
          <UikNavLink
            className={pathname === '/email_reminders' ? 'active' : ''}
            icon={<i className="fa fa-envelope" />}
            Component={Link}
            to="/email_reminders"
          >
            Email Reminders
          </UikNavLink>
        )}
        {currentUser && currentUser.inkblot_admin_yn && (
          <UikNavLink
            className={pathname === '/reporting' ? 'active' : ''}
            icon={<i className="fa fa-envelope" />}
            Component={Link}
            to="/reporting"
          >
            Reporting
          </UikNavLink>
        )}
        <UikNavLink
          className={pathname === '/sign_out' ? 'active' : ''}
          style={{ marginTop: '8px' }}
          icon={<i className="blank-space" />}
          onClick={() => signOut()}
        >
          Sign Out
        </UikNavLink>
      </UikNavSection>
    </UikNavPanel>
  );
}

SideBar.propTypes = {
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(withRouter(SideBar));
