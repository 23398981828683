/*
 *
 * CompanyCreation actions
 *
 */

import {
  FETCH_COMPANIES,
  FETCH_COMPANIES_ERROR,
  FETCH_COMPANIES_SUCCESS,
  FETCH_AMS,
  FETCH_AMS_ERROR,
  FETCH_AMS_SUCCESS,
  CREATE_COMPANY,
  CREATE_COMPANY_ERROR,
  EDIT_COMPANY,
  EDIT_COMPANY_ERROR,
  EDIT_COMPANY_SUCCESS,
  RESET_COMPANY_ERROR,
  CREATE_COMPANY_SUCCESS,
  UPLOAD_EMPLOYEE_CSV,
  UPLOAD_EMPLOYEE_CSV_SUCCESS,
  UPLOAD_EMPLOYEE_CSV_ERROR,
  FETCH_CP_NAVIGATORS_SUCCESS,
  FETCH_CP_NAVIGATORS_ERROR,
  FETCH_CP_NAVIGATORS,
  CREATE_LOGO,
  CREATE_LOGO_ERROR,
  CREATE_LOGO_SUCCESS,
} from './constants';

export function fetchCompanies() {
  return {
    type: FETCH_COMPANIES,
  };
}

export function fetchCompaniesSuccess(companies) {
  return {
    companies,
    type: FETCH_COMPANIES_SUCCESS,
  };
}

export function fetchCompaniesError(error) {
  return {
    error,
    type: FETCH_COMPANIES_ERROR,
  };
}

export function uploadEmployeeCsv(values, callback) {
  return {
    values,
    callback,
    type: UPLOAD_EMPLOYEE_CSV,
  };
}

export function uploadEmployeeCsvSuccess() {
  return {
    type: UPLOAD_EMPLOYEE_CSV_SUCCESS,
  };
}

export function uploadEmployeeCsvError(error) {
  return {
    error,
    type: UPLOAD_EMPLOYEE_CSV_ERROR,
  };
}

export function createCompany(values, callback) {
  return {
    values,
    callback,
    type: CREATE_COMPANY,
  };
}

export function createCompanySuccess(company) {
  return {
    company,
    type: CREATE_COMPANY_SUCCESS,
  };
}

export function createCompanyError(error) {
  return {
    error,
    type: CREATE_COMPANY_ERROR,
  };
}

export function editCompany(values, callback) {
  return {
    values,
    callback,
    type: EDIT_COMPANY,
  };
}

export function editCompanySuccess(company) {
  return {
    company,
    type: EDIT_COMPANY_SUCCESS,
  };
}

export function editCompanyError(error) {
  return {
    error,
    type: EDIT_COMPANY_ERROR,
  };
}

export function resetCompanyError() {
  return {
    type: RESET_COMPANY_ERROR,
  };
}

export function createLogo(values, companyId, callback) {
  return {
    values,
    companyId,
    callback,
    type: CREATE_LOGO,
  };
}

export function createLogoSuccess(company) {
  return {
    company,
    type: CREATE_LOGO_SUCCESS,
  };
}

export function createLogoError(error) {
  return {
    error,
    type: CREATE_LOGO_ERROR,
  };
}

export function fetchAccountManagers() {
  return {
    type: FETCH_AMS,
  };
}

export function fetchAccountManagersSuccess(ams) {
  return {
    ams,
    type: FETCH_AMS_SUCCESS,
  };
}

export function fetchAccountManagersError(error) {
  return {
    error,
    type: FETCH_AMS_ERROR,
  };
}

export function fetchClinicalNavigators(callback) {
  return {
    callback,
    type: FETCH_CP_NAVIGATORS,
  };
}

export function fetchClinicalNavigatorsSuccess(data) {
  return {
    data,
    type: FETCH_CP_NAVIGATORS_SUCCESS,
  };
}

export function fetchClinicalNavigatorsError(error) {
  return {
    error,
    type: FETCH_CP_NAVIGATORS_ERROR,
  };
}
