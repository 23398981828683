/**
 *
 * ContractDetails
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import styled from 'styled-components';

import { Button, Modal, Tooltip } from 'antd';
import AntDatePicker from 'components/AntDatePicker';
import AntCheckbox from 'components/AntCheckbox';
import moment from 'moment';

import { UikDivider } from '@uik/lib';

import makeSelectMyAccount from '../selectors';
import { fetchClinicalNavigators, toggleDisableCompany } from '../actions';

const DATE_FORMAT = 'DD/MM/YYYY';

export function ContractDetails(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [CPNavigatorsMap, setCPNavigatorsMap] = useState(null);
  useEffect(() => {
    if (CPNavigatorsMap) return;
    props.dispatchFetchClinicalNavigators((data) => {
      if (data && data.length) {
        const obj = data.reduce(
          (obj, e) => ({
            [e.id]: `${e.first_name} ${e.last_name} (ID: ${e.id})`,
            ...obj,
          }),
          {},
        );
        setCPNavigatorsMap(obj);
      }
    });
  });

  const {
    renderContractDetail,

    company,
    dispatchToggleDisableCompany,
    user,
    benefitCont,
    change,
    handleSubmit,
  } = props;

  const onSubmit = (values) => {
    values.id = company.id;
    dispatchToggleDisableCompany(values);
    setModalOpen(false);
  };
  if (props.company === undefined) {
    return <h2>Loading...</h2>;
  }

  const {
    contract_start,
    contract_end,
    employee_eligibility,
    dependants_yn,
    launch_date,
  } = company.contract_detail;
  const {
    clinical_navigator_id,
    wellness_hub_yn,
    tranquility_service_yn,
    tranquility_type,
    tranquility_url,
    newsletter_en_yn,
    newsletter_fr_yn,
    collaborative_care_yn,
    crisis_support_yn,
    maple_telemedicine_url,
    in_person_counselling_yn,
  } = company.company_service;
  const {
    special_notice_for_employees,
    special_notice_for_dependants,
    portal_gating,
    accepted_domains,
    employee_id_yn,
  } = company.company_info;
  const {
    video_bite_employee_minutes,
    video_bite_dependant_minutes,
    video_access_employee_minutes,
    video_access_dependant_minutes,
    video_couples_bite_employee_minutes,
    video_couples_bite_dependant_minutes,
    video_couples_access_employee_minutes,
    video_couples_access_dependant_minutes,
    unlimited_video_bite_employee,
    unlimited_video_access_employee,
    unlimited_video_bite_dependant,
    unlimited_video_access_dependant,
    health_work_life_minutes,
  } = company.company_minutes;

  const formattedContractStart = moment(contract_start)
    .tz('America/Toronto')
    .format('MMMM Do, YYYY');

  const formattedContractEnd = moment(contract_end)
    .tz('America/Toronto')
    .format('MMMM Do, YYYY');

  const basicsValues = [
    { title: 'company name', value: company.name },
    {
      title: 'portal page needed',
      value: renderContractDetail({ included: company.customized_url_yn }),
    },
    {
      title: 'partner/reseller',
      value: renderContractDetail({ included: company.reseller_name }),
    },
    { title: 'alias', value: company.alias },
    { title: 'portal url', value: company.customized_url },
    { title: 'reseller company', value: company.reseller_name },
    { title: 'company code', value: company.company_info.company_code },
    {
      title: 'newsletter',
      value: (
        <div>
          <div>
            {renderContractDetail({
              name: 'French',
              included: newsletter_fr_yn,
            })}
          </div>
          <div>
            {renderContractDetail({
              name: 'English',
              included: newsletter_en_yn,
            })}
          </div>
        </div>
      ),
    },
    {
      title: 'registration redesign',
      value: (
        <div>
          <div>
            {renderContractDetail({
              name: 'Legacy',
              included: company.company_info.registration_flow === 'legacy',
            })}
          </div>
          <div>
            {renderContractDetail({
              name: 'Registration 2023',
              included:
                company.company_info.registration_flow === 'redesign_2023',
            })}
          </div>
        </div>
      ),
    },
    {
      title: 'insurance claims enabled',
      value: renderContractDetail({
        included: company.company_info.insurance_claims_enabled,
      }),
    },
    {
      title: 'GS+ Migration',
      value: renderContractDetail({
        included: company.company_info.gsplus_migration_yn,
      }),
    },
    {
      title: 'GS+ Migration Date',
      value:
        company.company_info.gsplus_migration_date &&
        moment(company.company_info.gsplus_migration_date)
          .tz('America/Toronto')
          .format('MMMM Do, YYYY'),
    },
    {
      title: 'ADVANTAGE MIGRATION',
      value: renderContractDetail({
        included: company.company_info.advantage_utilization_start_from,
      }),
    },
    {
      title: 'REFRESH ALL CLIENT HOURS UTILIZATION DURING MIGRATION',
      value: renderContractDetail({
        included:
          company.company_info.advantage_utilization_start_from ==
          'migration_date'
            ? true
            : false,
      }),
    },
  ];

  const portalPageNeededValues = [
    <div key={'portal-needed'} className="value-header">
      portal page needed
    </div>,
    renderContractDetail({ included: company.customized_url_yn }),
  ];

  const planTypeValues = [
    <div key={'plan'} className="value-header">
      plan type
    </div>,
    `${company.plan_type && company.plan_type.toUpperCase()}`,
  ];

  const portalGatingValues = [
    <div key={'portal-gating'} className="value-header">
      portal gating
    </div>,
    `${portal_gating}`,
  ];

  const advisoryServicesValues = [
    <div key={'services'} className="value-header">
      Services
    </div>,
    renderContractDetail({ name: 'Wellness Hub', included: wellness_hub_yn }),
    company.advisory_services &&
      renderContractDetail({
        name: 'Financial',
        included: company.advisory_services.financial_yn,
      }),
    company.advisory_services &&
      renderContractDetail({
        name: 'Legal',
        included: company.advisory_services.legal_yn,
      }),
    company.advisory_services &&
      renderContractDetail({
        name: 'Life Transitions',
        included: company.advisory_services.research_yn,
      }),
    company.advisory_services &&
      renderContractDetail({
        name: 'Health',
        included: company.advisory_services.health_yn,
      }),
    company.advisory_services &&
      renderContractDetail({
        name: 'Career',
        included: company.advisory_services.career_yn,
      }),
    company.advisory_services &&
      renderContractDetail({
        name: 'Emergency Loan',
        included: company.advisory_services.loan_yn,
      }),
  ];

  const additionalServicesValues = [
    <div key={'settings'} className="value-header">
      Additional Settings
    </div>,
    renderContractDetail({ name: 'Dependants', included: dependants_yn }),
    company.advisory_services &&
      renderContractDetail({
        name: 'In Person Counselling',
        included: in_person_counselling_yn,
      }),
    renderContractDetail({
      name: 'Crisis Support',
      included: crisis_support_yn,
    }),
    renderContractDetail({
      name: 'Collaborative Care',
      included: collaborative_care_yn,
    }),
  ];

  const minutesValues = [
    <div key={'minutes'} className="value-header">
      Minutes
    </div>,
    company.plan_type === 'eap' ? (
      <>
        <div>{`Employee EAP Minutes (Individual): ${video_bite_employee_minutes}`}</div>
        <div>{`Dependant EAP Minutes (Individual): ${video_bite_dependant_minutes}`}</div>
        <div>
          {renderContractDetail({
            name: 'Unlimited EAP Employee Minutes (Individual)',
            included: unlimited_video_bite_employee,
          })}
        </div>
        <div>
          {renderContractDetail({
            name: 'Unlimited EAP Dependant Minutes (Individual)',
            included: unlimited_video_bite_dependant,
          })}
        </div>
        <div>{<div className="value-header">Couples Counselling</div>}</div>
        <div>{`Employee EAP Minutes (Couples): ${video_couples_bite_employee_minutes}`}</div>
        <div>{`Dependant EAP Minutes (Couples): ${video_couples_bite_dependant_minutes}`}</div>
      </>
    ) : (
      <>
        <div>{`Employee Access Minutes (Individual): ${video_access_employee_minutes}`}</div>
        <div>{`Dependant Access Minutes (Individual): ${video_access_dependant_minutes}`}</div>
        <div>
          {renderContractDetail({
            name: 'Unlimited Access Employee Minutes (Individual)',
            included: unlimited_video_access_employee,
          })}
        </div>
        <div>
          {renderContractDetail({
            name: 'Unlimited Access Dependant Minutes (Individual)',
            included: unlimited_video_access_dependant,
          })}
        </div>
        <div>{<div className="value-header">Couples Counselling</div>}</div>
        <div>{`Employee Access Minutes (Couples): ${video_couples_access_employee_minutes}`}</div>
        <div>{`Dependant Access Minutes (Couples): ${video_couples_access_dependant_minutes}`}</div>
      </>
    ),
    <>{`Career/Health/Life Minutes: ${health_work_life_minutes}`}</>,
  ];

  const tranquilityValues = [
    <div key={'tranquility'} className="value-header">
      Tranquility
    </div>,
    renderContractDetail({
      name: 'Tranquility Access',
      included: tranquility_service_yn,
    }),
    tranquility_type >= 0 &&
      `Type: ${tranquility_type === 0 ? 'Self-Directed' : 'Coach-Directed'}`,
    tranquility_url && `Tranquility URL: ${tranquility_url}`,
  ];

  const mapleValues = [
    <div key={'maple'} className="value-header">
      Maple
    </div>,
    renderContractDetail({
      name: 'Maple Access',
      included: maple_telemedicine_url,
    }),
    maple_telemedicine_url && `Maple URL: ${maple_telemedicine_url}`,
  ];

  const clinicalNavigatorValues = [
    <div key={'clinical'} className="value-header">
      Clinical Navigator
    </div>,
    renderContractDetail({
      name: 'Clinical Navigator Access',
      included: !!clinical_navigator_id,
    }),
    CPNavigatorsMap &&
      clinical_navigator_id &&
      `Selected Clinical Navigator: Inkblot Nurse ${CPNavigatorsMap[clinical_navigator_id]}`,
  ];

  const eligibilityListValues = [
    <div key={'eligibility'} className="value-header">
      Eligibity List
    </div>,
    renderContractDetail({ included: employee_eligibility }),
  ];

  const employeeIdvalues = [
    <div key={'employee'} className="value-header">
      Employee ID
    </div>,
    renderContractDetail({ included: employee_id_yn }),
  ];

  const customGatingValues = [
    <div key="custom_gating" className="value-header">
      Custom Gating Settings
    </div>,
    <>
      <div>{`ID Custom Name: ${company.company_info?.id_custom_name}`}</div>
      <div>Gating Instructions:</div>
      <div
        dangerouslySetInnerHTML={{
          __html: company.company_info?.gating_instructions,
        }}
      />
      <div>{`ID Custom Name (FR): ${company.company_info?.id_custom_name_fr}`}</div>
      <div>Gating Instructions (FR):</div>
      <div
        dangerouslySetInnerHTML={{
          __html: company.company_info?.gating_instructions_fr,
        }}
      />
    </>,
  ];

  const acceptedDomainValues = [
    <div key={'domains'} className="value-header">
      Accepted Email Domains
    </div>,
    <div key={'domains-value'}>
      {accepted_domains.map((domain) => `${domain}, `)}
    </div>,
  ];

  const providerCoveragesValues = [
    <div key={'provider'} className="value-header">
      Provider Coverages
    </div>,
    <div key={'provider-value'} style={{ display: 'inline-block' }}>
      {company.tag_names.map((provider) => `${provider}, `)}
    </div>,
  ];

  const specialNoticeValues = [
    <div key={'employees'} className="value-header">
      For Employees
    </div>,
    renderContractDetail({ included: special_notice_for_employees }),
    special_notice_for_employees && (
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: special_notice_for_employees }}
      />
    ),
    <div key={'dependents'} className="value-header">
      For Dependants
    </div>,
    renderContractDetail({ included: special_notice_for_dependants }),
    special_notice_for_dependants && (
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: special_notice_for_dependants }}
      />
    ),
  ];

  const RenderValues = (value) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {value.map((item, index) => {
          return <div key={index + 1}>{item}</div>;
        })}
      </div>
    );
  };

  return (
    <div>
      <h3 className="sub-header title">Contract Dates</h3>
      <ContractDatesWrapper>
        <div>
          <div className="grid-header">Account status</div>
          <div style={{ color: 'green' }}>
            <p>
              {company.suspended_yn ? (
                <b style={{ color: 'red' }}>
                  SUSPENDED on{' '}
                  {moment(company.suspended_at).format(
                    'MMMM Do, YYYY at HH:mm',
                  )}
                </b>
              ) : (
                <b style={{ color: 'green' }}>ACTIVE</b>
              )}
            </p>
            {company.benefit_continuance_end_date && (
              <>
                <p>
                  Benefit Continuance until:{' '}
                  {moment(company.benefit_continuance_end_date).format(
                    'MMMM Do, YYYY',
                  )}
                </p>
                <p style={{ color: 'black' }}>
                  Benefits may continue past the contract end date so that
                  employees who have already had 1 or more appointments can
                  continue to use their sponsored hours for a set period of
                  time.
                </p>
              </>
            )}
          </div>
        </div>

        <div>
          <div className="grid-header">Contract Start Date</div>
          <div>
            {contract_end !== null
              ? `${formattedContractStart} - ${formattedContractEnd}`
              : `${formattedContractStart}`}
          </div>
        </div>

        <div>
          <div className="grid-header">contract launch date</div>
          <div>
            <div>{moment(launch_date).format('MMMM Do, YYYY')}</div>
          </div>
        </div>

        <div>
          {user.inkblot_admin_yn && (
            <>
              {!company.suspended_yn ? (
                <Button
                  onClick={() => setModalOpen(true)}
                  htmlType="submit"
                  type="danger"
                >
                  Suspend Account
                </Button>
              ) : (
                <Button
                  onClick={() => dispatchToggleDisableCompany(company)}
                  htmlType="submit"
                  type="primary"
                >
                  Unsuspend Account
                </Button>
              )}
            </>
          )}
        </div>
      </ContractDatesWrapper>
      <UikDivider />
      <h3 className="sub-header title">Account Owners</h3>
      <AccountManagerWrapper>
        <div>
          <div className="grid-header">account manager</div>
          <div>{company.account_manager && company.account_manager.name}</div>
        </div>
        <div>
          <div className="grid-header">secondary account manager</div>
          <div>
            {company.secondary_account_manager &&
              company.secondary_account_manager.name}
          </div>
        </div>
      </AccountManagerWrapper>
      <UikDivider />
      <h3 className="sub-header title">Basics</h3>
      <BasicsWrapper>
        {basicsValues.map((item, index) => {
          return (
            <div key={index + 1}>
              <div className="grid-header">{item.title}</div>
              <div>{item.value}</div>
            </div>
          );
        })}
      </BasicsWrapper>
      <UikDivider />
      <h3 className="sub-header title">Benefit Information</h3>
      <BenefitWrapper>
        {RenderValues(planTypeValues)}
        {RenderValues(portalGatingValues)}
        {RenderValues(employeeIdvalues)}
        {RenderValues(customGatingValues)}
        {RenderValues(eligibilityListValues)}
        {RenderValues(portalPageNeededValues)}
        {RenderValues(acceptedDomainValues)}
        {company.advisory_services && RenderValues(advisoryServicesValues)}
        {RenderValues(additionalServicesValues)}
        {RenderValues(minutesValues)}
        {RenderValues(providerCoveragesValues)}
      </BenefitWrapper>
      <UikDivider />
      <h3 className="sub-header title">Other Services</h3>
      <BenefitWrapper>
        {RenderValues(tranquilityValues)}
        {RenderValues(mapleValues)}
        {RenderValues(clinicalNavigatorValues)}
      </BenefitWrapper>
      <UikDivider />
      <h3 className="sub-header title">Special Notice</h3>
      <SpecialNoticeWrapper>
        {RenderValues(specialNoticeValues)}
      </SpecialNoticeWrapper>
      <Modal
        onCancel={() => setModalOpen(false)}
        onOk={handleSubmit(onSubmit)}
        title="Remove Employee"
        visible={modalOpen}
      >
        <form>
          <p style={{ marginBottom: 30 }}>
            Are you sure you want to suspend {company.name}?
          </p>
          <Tooltip title="Allows employees who've used the service to continue using benefits until a certain date.">
            <Field
              component={AntCheckbox}
              label="Benefit Continuance?"
              name="benefit_continuance_yn"
              style={{ marginBottom: 15 }}
              wrapperwidth="200px"
            />
          </Tooltip>

          {benefitCont && (
            <Field
              component={AntDatePicker}
              label="Benefits end date*"
              name="benefit_continuance_end_date"
              onChange={(_date, dateString) =>
                change('benefit_continuance_end_date', dateString)
              }
              placeholder={DATE_FORMAT}
            />
          )}
          <br />
          <sub style={{ color: 'rgb(158, 160, 165)' }}>
            <i>
              * when no date is provided, default is three months from entry
            </i>
          </sub>
        </form>
      </Modal>
    </div>
  );
}

ContractDetails.propTypes = {
  dispatchFetchClinicalNavigators: PropTypes.func.isRequired,
  benefitCont: PropTypes.any,
  change: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
  renderContractDetail: PropTypes.func.isRequired,
  renderAccountDetail: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  dispatchToggleDisableCompany: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const formSelector = formValueSelector('ContractDetailForm');

  const sagaMapStateToProps = createStructuredSelector({
    myAccount: makeSelectMyAccount(),
  })(state);

  return {
    benefitCont: formSelector(state, 'benefit_continuance_yn'),
    ...sagaMapStateToProps,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatchToggleDisableCompany: (values, callback) =>
      dispatch(toggleDisableCompany(values, callback)),
    dispatchFetchClinicalNavigators: (callback) =>
      dispatch(fetchClinicalNavigators(callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'ContractDetailForm',
  })(ContractDetails),
);

const ContractDatesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 12px;

  .grid-header {
    font-family: 'Arial';
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }
`;

const BenefitWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px 0px;
  margin-bottom: 100px;
  .value-header {
    font-family: 'Arial';
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .description {
    font-family: 'Arial';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`;

const SpecialNoticeWrapper = styled(BenefitWrapper)`
  grid-template-columns: 1fr;
`;

const AccountManagerWrapper = styled(ContractDatesWrapper)`
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 12px;
`;

const BasicsWrapper = styled(ContractDatesWrapper)`
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 12px;
`;
